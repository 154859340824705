
import NdaIcon from "@/components/NdaIcon"
import Vue, { PropType } from "vue"
import {
  Project,
  ProjectFeatureFlag,
  ProjectStatus,
  ProjectArchiveTier,
} from "@evercam/shared/types/project"
import { mapStores } from "pinia"
import { useProjectStore } from "@/stores/project"
import { _3dViewer } from "@evercam/shared/types"
import { useAccountStore } from "@/stores/account"
import { VIcon } from "vuetify/lib"

export default Vue.extend({
  name: "ProjectCard",
  components: { NdaIcon, VIcon },
  props: {
    project: {
      type: Object as PropType<Project>,
      default: () => ({} as Project),
    },
  },
  data() {
    return {
      url: "" as string,
      isActive: false as boolean,
      viewer: _3dViewer,
    }
  },
  computed: {
    ...mapStores(useProjectStore, useAccountStore),
    hasNameEllipsis() {
      const el = this.$refs?.nameEllipsis?.$el as HTMLElement

      return el ? el.offsetWidth < el.scrollWidth : false
    },
    hasStatusEllipsis() {
      const el = this.$refs?.statusEllipsis as HTMLElement

      return el ? el.offsetWidth < el.scrollWidth : false
    },
    projectStatus() {
      if (this.project.status === ProjectStatus.Completed) {
        return `Archived ${
          this.project.archiveTier ? ` - ${this.project.archiveTier}` : ""
        }`
      } else {
        return this.project.status
      }
    },
    isUnderNda() {
      return this.project?.featureFlags?.includes(ProjectFeatureFlag.NdaMc)
    },
    camerasCountColor(): string {
      return this.$vuetify.theme.dark ? "#10131b" : "grey darken-1"
    },
    featureIcons() {
      const exid = this.project.exid

      return [
        {
          label: this.$t("sections.gate_report"),
          icon: "fas fa-truck-moving",
          component: "VIcon",
          to: `/v2/projects/${exid}/gate-report`,
          enabled: this.$permissions.project.has.gateReport(this.project),
          size: 16,
        },
        {
          label: this.$t("sections.BIMView"),
          icon: "imodel",
          component: "ESvgIcon",
          to: `/v2/projects/${exid}/bim`,
          enabled: this.$permissions.project.has.BIMView(this.project),
          size: 24,
        },
        {
          label: this.$t("sections.droneView"),
          icon: "drone-view",
          component: "ESvgIcon",
          to: `/v2/projects/${exid}/drone`,
          enabled: this.$permissions.project.has.droneView(this.project),
          size: 22,
        },
        {
          label: this.$t("sections._360View"),
          icon: "the360",
          component: "ESvgIcon",
          to: `/v2/projects/${exid}/360`,
          enabled: this.$permissions.project.has._360View(this.project),
          size: 22,
        },
      ]
    },
  },
  watch: {
    "projectStore.selectedProject": {
      deep: true,
      handler() {
        window.stop()
      },
    },
  },
  mounted() {
    this.url = `${this.$config.public.apiURL}/projects/${this.project.exid}/thumbnail?authorization=${this.accountStore.token}`
  },
  methods: {
    onError() {
      this.url = require("~/assets/img/waiting_for_activation.jpg")
      this.$emit("error", this.url)
    },
    getHoverStyle(hover = false) {
      if (this.$vuetify.theme.dark) {
        return `${hover ? "hovered" : ""} lighten-${hover ? "2" : "1"}`
      }

      return `${hover ? "hovered" : ""} darken-${hover ? "2" : "1"}`
    },
    getIconColor(enabledFeature) {
      if (enabledFeature) {
        return this.$vuetify.theme.currentTheme.on_background
      }

      return this.$vuetify.theme.dark ? "rgba(218,221,227,0.3)" : "#757575"
    },
    getProjectArchiveDescription(archiveTier) {
      if (!archiveTier) {
        return
      }
      const archiveTierDescriptions = {
        [ProjectArchiveTier.Minimal]: this.$t(
          "content.minimal_archive_description"
        ),
        [ProjectArchiveTier.Partial]: this.$t(
          "content.partial_archive_description"
        ),
      }

      return ` : ${
        archiveTierDescriptions[archiveTier]
          ? archiveTierDescriptions[archiveTier]
          : this.$t("content.full_archive_description")
      }`
    },
  },
})
